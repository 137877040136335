import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { Button } from "@dev-spendesk/grapes";

import { FieldLayout } from "../FieldLayout/FieldLayout";
import { Modal } from "../Modal/Modal";
import { useNode } from "../../contexts/TreeContext";
import { isEditAllowed } from "../../utils/status";
import type { FieldType } from "../../utils/schemaBuilder";
import type { FormValues } from "../../pages/ExtraFormPage/ExtraFormPage";

import "./Field.scss";

type Props = {
  fieldId: string;
  field: FieldType;
  children: React.ReactNode;
};
export function Field({ field, fieldId, children }: Props) {
  const ref = React.useRef<FormValues>();
  const [modalIsOpen, setOpenModal] = React.useState(false);
  const { t } = useTranslation();
  const node = useNode(fieldId);
  const { errors, values, setValues, validateForm, setFieldTouched } =
    useFormikContext<FormValues>();
  const { label, placeholder, additionalInformation } = field.properties;

  const hasError = node.hasError(errors, values);
  const status = node.getStatus(values);

  async function handleSave() {
    const newErrors = await validateForm();
    const leafs = node.leafsIds(values);
    const containsError = leafs.reduce((acc, nodeId) => {
      setFieldTouched(nodeId, true, false);
      return acc || !!newErrors[nodeId];
    }, false);

    if (!containsError) {
      setOpenModal(false);
    }
  }

  function handleRollback() {
    if (ref.current) {
      setValues(ref.current);
    }
    setOpenModal(false);
  }

  React.useEffect(() => {
    if (modalIsOpen) {
      // When modal open, save form value to be able to rollback
      ref.current = values;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  return (
    <FieldLayout
      status={status}
      label={label}
      placeholder={placeholder}
      additionalInformation={additionalInformation}
    >
      {isEditAllowed(status) && (
        <Button
          text={!hasError ? t("editInformation") : t("fillInformation")}
          variant={!hasError ? "secondary" : "primary"}
          type="button"
          onClick={() => setOpenModal(true)}
        />
      )}

      <Modal
        isOpen={modalIsOpen}
        actions={[
          <Button
            key="no"
            text={t("informations.return")}
            variant="secondary"
            onClick={handleRollback}
          />,
          <Button
            key="yes"
            text={t("informations.save")}
            variant="primary"
            type="submit"
            onClick={handleSave}
          />,
        ]}
        iconName="info"
        iconVariant="primary"
        title={placeholder}
      >
        <div className="Field__content">{children}</div>
      </Modal>
    </FieldLayout>
  );
}
