import React from "react";
import { useTranslation } from "react-i18next";

import {
  useStatus,
  PROGRESS_STATUS,
  STATUS_START,
  STATUS_ONGOING,
  STATUS_DONE,
} from "../../contexts/StatusContext";

import "./AsideBar.scss";

function getContentFromStatus(status: PROGRESS_STATUS): {
  title: string;
  description: string;
  imgSrc: string;
} {
  switch (status) {
    case STATUS_START:
    default:
      return {
        title: `status.${STATUS_START}.title`,
        description: `status.${STATUS_START}.description`,
        imgSrc: `${process.env.PUBLIC_URL}/static/img/running_plane.svg`,
      };
    case STATUS_ONGOING:
      return {
        title: `status.${status}.title`,
        description: `status.${status}.description`,
        imgSrc: `${process.env.PUBLIC_URL}/static/img/tom_and_guilia.svg`,
      };
    case STATUS_DONE:
      return {
        title: `status.${status}.title`,
        description: `status.${status}.description`,
        imgSrc: `${process.env.PUBLIC_URL}/static/img/rock_yeah.svg`,
      };
  }
}

const AsideBar = () => {
  const { t } = useTranslation();
  const status = useStatus();

  const { title, description, imgSrc } = getContentFromStatus(status);

  return (
    <aside className="AsideBar">
      <div className="AsideBar__container">
        <img
          className="AsideBar__logo"
          src={`${process.env.PUBLIC_URL}/static/img/spendesk-logo.svg`}
          alt="Spendesk logo"
        />
      </div>
      <div className="AsideBar__container-middle">
        <img className="AsideBar__illustration" src={imgSrc} alt="status" />
      </div>
      <div className="AsideBar__container">
        <p className="AsideBar__title">{t(title)}</p>
        <p className="AsideBar__desc">{t(description)}</p>
      </div>
    </aside>
  );
};

export default AsideBar;
