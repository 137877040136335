import { Integration, EventProcessor, Event } from "@sentry/types";

export class FullStoryIntegration implements Integration {
  public readonly name: string = FullStoryIntegration.id;
  public static id: string = "FullStoryIntegration";

  setupOnce(addGlobalEventProcessor: (callback: EventProcessor) => void): void {
    addGlobalEventProcessor((event): Event => {
      if (
        "FS" in window &&
        typeof window.FS.getCurrentSessionURL === "function"
      ) {
        // eslint-disable-next-line no-param-reassign
        event.contexts = {
          ...event.contexts,
          fullStory: {
            fullStoryUrl: window.FS.getCurrentSessionURL(true),
          },
        };
      }

      return event;
    });
  }
}
