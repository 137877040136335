import React from "react";

import FileField from "../components/FileField/FileField";
import { SelectMultipleField } from "../components/SelectMultipleField/SelectMultipleField";
import { SelectField } from "../components/SelectField/SelectField";
import { InputField } from "../components/InputField/InputField";
import { Field } from "../components/Field/Field";

import type { FormValues } from "../pages/ExtraFormPage/ExtraFormPage";

import {
  useTree,
  getNode,
  ROOT_NODE,
  Node,
  Tree,
  isNodeVisible,
} from "../contexts/TreeContext";

export type Condition = {
  field: string;
  operator: "eq";
  value: string;
};

export type FieldType =
  | FileType
  | SelectMultipleType
  | SelectType
  | InputType
  | FieldSet;

export type SelectMultipleType = {
  id: string;
  type: "select_multiple";
  properties: {
    conditions?: Condition[];
    label: string;
    placeholder: string;
    additionalInformation?: string;
    choices: {
      label: string;
      value: string;
    }[];
  };
};

export type SelectType = {
  id: string;
  type: "select";
  properties: {
    conditions?: Condition[];
    label: string;
    placeholder: string;
    additionalInformation?: string;
    choices: {
      label: string;
      value: string;
    }[];
  };
};

export type InputType = {
  id: string;
  type: "input";
  properties: {
    conditions?: Condition[];
    label: string;
    placeholder: string;
    additionalInformation?: string;
  };
};

export type FileType = {
  id: string;
  type: "file";
  properties: {
    conditions?: Condition[];
    key: string;
    label: string;
    placeholder: string;
    additionalInformation?: string;
  };
};

export type FieldSet = {
  type: "fieldset";
  properties: {
    conditions?: Condition[];
    label: string;
    placeholder: string;
    additionalInformation?: string;
    fieldsIds: string[];
  };
};

export type Layout = {
  type: string;
  fieldsIds: string[];
};

export type Schema = {
  layout: Layout[];
  fields: Record<string, FieldType>;
};

const DummyComponent = () => null;
const mappingComponent = {
  input: InputField,
  select_multiple: SelectMultipleField,
  select: SelectField,
};

function renderNodeTree(
  tree: Tree,
  node: Node,
  nodeId: string,
  values: FormValues
): React.ReactNode {
  const { descendantsIds } = node;
  if (descendantsIds.length > 0) {
    return descendantsIds.map((descendantNodeId) => {
      const descendant = getNode(tree, descendantNodeId);
      return renderNodeTree(tree, descendant, descendantNodeId, values);
    });
  }
  if (node.field && isNodeVisible(tree, nodeId, values)) {
    const { field } = node;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore ComponentToRender will be defined in any case
    const ComponentToRender = mappingComponent[field.type] ?? DummyComponent;
    return <ComponentToRender key={nodeId} fieldId={nodeId} field={field} />;
  }
  return null;
}

export function SchemaBuilder({ values }: { values: FormValues }) {
  const tree = useTree();
  const rootNode = getNode(tree, ROOT_NODE);

  return (
    <>
      {rootNode.descendantsIds.map((nodeId) => {
        if (isNodeVisible(tree, nodeId, values, 0)) {
          const node = getNode(tree, nodeId);
          if (node.field) {
            const { field } = node;
            if (field.type === "file") {
              return (
                <FileField
                  key={nodeId}
                  fieldId={nodeId}
                  field={field}
                  status={node.status}
                />
              );
            }
            return (
              <Field field={field} fieldId={nodeId}>
                {renderNodeTree(tree, node, nodeId, values)}
              </Field>
            );
          }
        }
        return null;
      })}
    </>
  );
}
