import React from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { Tag, IconButton, Button } from "@dev-spendesk/grapes";

import { FieldLayout } from "../FieldLayout/FieldLayout";
import PreviewModal from "../PreviewModal/PreviewModal";
import {
  uploadFiles,
  deleteFile,
  FileUploaded,
  getFilePreviewUrl,
  isFileTooLarge,
  FILE_UPLOAD_LIMIT_MB,
} from "../../utils/files";
import { isEditAllowed } from "../../utils/status";
import type { FileType } from "../../utils/schemaBuilder";

import "./FileField.scss";

type Props = {
  field: FileType;
  fieldId: string;
  status?: string;
};
function FileField({ field, fieldId, status }: Props) {
  const { companyId } = useParams<{ companyId: string }>();
  const { key, label, placeholder, additionalInformation } = field.properties;
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<Record<string, string>>();

  const fileId = values[fieldId];
  const initialValue = fileId
    ? { id: fileId, name: fileId, url: getFilePreviewUrl(fileId) }
    : null;

  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [isPreviewOpen, setPreviewOpen] = React.useState<boolean>(false);
  const [fileUpload, setFileUploaded] = React.useState<FileUploaded | null>(
    initialValue
  );
  const hasError = touched[fieldId] && errors[fieldId];

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFieldTouched(fieldId, true, false);
    const { files } = e.target;
    const file = e.target.files?.[0];

    if (isFileTooLarge(file)) {
      return setFieldError(
        fieldId,
        t("filesUpload.fileTooLarge", { size: FILE_UPLOAD_LIMIT_MB })
      );
    }

    if (file && files) {
      setFieldError(fieldId, undefined); // reset field error
      setUploading(true);
      try {
        const filesUploaded = await uploadFiles(companyId, key, files, "kyb");
        const fileUploaded = filesUploaded.pop();
        if (fileUploaded) {
          setFileUploaded(fileUploaded);
          setFieldValue(fieldId, fileUploaded.id);
        }
      } catch (err) {
        setFieldError(fieldId, t("filesUpload.uploadFailed"));
      } finally {
        setUploading(false);
      }
    }
  }

  function handleDeleteFile() {
    setFieldValue(fieldId, null);
    setFileUploaded(null);
    deleteFile(companyId, values[fieldId], "kyb");
  }

  return (
    <FieldLayout
      label={label}
      placeholder={placeholder}
      additionalInformation={additionalInformation}
      status={status}
    >
      {fileUpload ? (
        <>
          <Button
            text={t("viewDocument")}
            variant="ghost"
            className="FileField__filename"
            onClick={() => setPreviewOpen(true)}
          />
          {isEditAllowed(status) && (
            <IconButton
              iconName="trash"
              onClick={handleDeleteFile}
              variant="border"
              ariaLabel={t("remove")}
            />
          )}
          <PreviewModal
            onClose={() => setPreviewOpen(false)}
            isOpen={isPreviewOpen}
            title={fileUpload.name}
          >
            <img
              src={fileUpload.url}
              alt={fileUpload.name}
              className="FileField__preview"
            />
          </PreviewModal>
        </>
      ) : (
        <>
          {hasError && (
            <Tag fit="content" text={`${errors[fieldId]}`} variant="alert" />
          )}
          <label
            htmlFor={fieldId}
            className={`FileField__label ${
              isUploading && "FileField__loading"
            }`}
          >
            {t("filesUpload.uploadFile")}
            <input
              id={fieldId}
              type="file"
              onChange={handleChange}
              className="FileField__input"
              accept=".jpg, .png, .pdf, .gif"
              disabled={isUploading}
            />
          </label>
        </>
      )}
    </FieldLayout>
  );
}

FileField.defaultProps = {
  status: undefined,
};

export default FileField;
