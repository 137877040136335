import React from "react";
import { Button } from "@dev-spendesk/grapes";

import { useUser, useSupervisor } from "../../contexts/UserContext";
import { logout } from "../../utils/logout";

import "./Supervision.scss";

export const Supervision = () => {
  const user = useUser();
  const supervisor = useSupervisor();

  if (!supervisor) {
    return null;
  }

  return (
    <div className="Supervision">
      Welcome {supervisor?.name}! You are logged in as {user?.fullname}.
      <Button
        onClick={logout}
        text="logout"
        variant="ghost"
        className="Supervision__logout"
      />
    </div>
  );
};
