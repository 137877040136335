import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/react";

import { api } from "../utils/api";
import { loadAnalytics } from "../utils/analytics";

const UserStateContext = React.createContext<User | undefined>(undefined);
const SupervisorContext =
  React.createContext<Supervisor | undefined | null>(null);
export const useUser = () => React.useContext(UserStateContext);
export const useSupervisor = () => React.useContext(SupervisorContext);

type Supervisor = {
  email: string;
  name: string;
};

type User = {
  id: string;
  fullname: string;
  intercomHash: string;
  lang: string;
  email: string;
  isAdmin: boolean;
  isController: boolean;
  isRequest: boolean;
  isAccountOwner: boolean;
};
type Company = {
  id: string;
  organisationId: string;
  name: string;
  created_from: string;
  type: string;
  country: string;
  currency: string;
};
type UserData = {
  user: User;
  company: Company;
  analytics: { key: string };
  supervisor?: Supervisor;
};
export const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { companyId } = useParams<{ companyId: string }>();

  const { data } = useQuery(
    ["user", companyId],
    () =>
      api.get<UserData>("/me/onboarding", {
        companyId,
        domain: "kyb",
      }),
    {
      staleTime: Infinity,
      suspense: true,
    }
  );

  React.useEffect(() => {
    if (data) {
      const { user, company, analytics, supervisor } = data.data;

      loadAnalytics({
        user: {
          id: user.id,
          intercom_hash: user.intercomHash,
          lang: user.lang,
          fullname: user.fullname,
          email: user.email,
          is_account_owner: user.isAccountOwner,
          is_admin: user.isAdmin,
          is_controller: user.isController,
          is_requester: user.isRequest,
        },
        company: {
          id: company.id,
          organisation_id: company.organisationId,
          name: company.name,
          created_from: company.created_from,
          type: company.type,
        },
        settings: analytics,
        supervisor,
      });

      Sentry.setUser({
        id: user.id,
        lang: user.lang,
      });

      Sentry.setContext("company", {
        id: company.id,
        country: company.country,
        currency: company.currency,
      });
    }
  }, [data]);

  return (
    <UserStateContext.Provider value={data?.data.user}>
      <SupervisorContext.Provider value={data?.data.supervisor}>
        {children}
      </SupervisorContext.Provider>
    </UserStateContext.Provider>
  );
};
