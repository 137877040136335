import React from "react";
import { InfoTip, Tag } from "@dev-spendesk/grapes";

import "./FieldLayout.scss";
import { useTranslation, TFunction } from "react-i18next";

function getTagPropFromStatus(
  t: TFunction<"translation">,
  status?: string
): {
  variant: "neutral" | "success" | "alert";
  text: string;
} | null {
  switch (status) {
    case "waiting_for_review":
      return {
        variant: "neutral",
        text: t("documentStatus.inReview"),
      };

    case "approved":
      return {
        variant: "success",
        text: t("documentStatus.approved"),
      };
    case "rejected":
      return {
        variant: "alert",
        text: t("documentStatus.rejected"),
      };

    default:
      return null;
  }
}

type Props = {
  label: string;
  placeholder?: string;
  additionalInformation?: string;
  status?: string;
};
export function FieldLayout({
  label,
  placeholder,
  additionalInformation,
  status,
  children,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const tagProps = getTagPropFromStatus(t, status);

  return (
    <section className="FieldLayout__container">
      <div className="FieldLayout__text">
        <p className="FieldLayout__label">{label}</p>
        <div className="FieldLayout__caption">
          {placeholder && (
            <span className="FieldLayout__description">{placeholder}</span>
          )}
          {additionalInformation && (
            <InfoTip
              content={additionalInformation}
              aria-label="additionalInformation"
            />
          )}
        </div>
      </div>
      {children}
      {tagProps && <Tag fit="content" {...tagProps} />}
    </section>
  );
}
