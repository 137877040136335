import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Tag } from "@dev-spendesk/grapes";
import { useFormikContext } from "formik";

import { Modal } from "../Modal/Modal";

import "./BottomBar.scss";

type Props = {
  primaryAction: () => void;
  secondaryAction: () => void;
  error?: boolean;
};

const BottomBar = ({
  primaryAction,
  secondaryAction,
  error = false,
}: Props) => {
  const { t } = useTranslation();
  const { isValid, isSubmitting } = useFormikContext();
  const [modalIsOpen, openModal] = React.useState(false);

  function handleSubmit() {
    if (isValid) {
      primaryAction();
    } else {
      openModal(true);
    }
  }

  return (
    <div className="BottomBar">
      <div className="BottomBar__container" aria-hidden={modalIsOpen}>
        <div className="BottomBar__primary">
          <Button
            text={t("submit")}
            variant="primary"
            type="button"
            onClick={handleSubmit}
            isDisabled={isSubmitting}
            className="BottomBar__submit"
          />
          {error && (
            <Tag iconName="failure" text={t("failSubmit")} variant="alert" />
          )}
        </div>
        <Button
          text={t("saveAndReturn")}
          variant="secondary"
          onClick={secondaryAction}
          isDisabled={isSubmitting}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        actions={[
          <Button
            key="cancel"
            text={t("cancel")}
            variant="secondary"
            onClick={() => openModal(false)}
          />,
          <Button
            key="saveAndReturn"
            text={t("saveAndReturn")}
            variant="primary"
            type="submit"
            onClick={secondaryAction}
          />,
        ]}
        iconName="lock"
        iconVariant="primary"
        title={t("invalidFormModal.title")}
        subtitle={t("invalidFormModal.content")}
      />
    </div>
  );
};

BottomBar.defaultProps = {
  error: false,
};

export default BottomBar;
