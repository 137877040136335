import React from "react";
import { Modal as GrapesModal, IconName } from "@dev-spendesk/grapes";

type Props = {
  actions: React.ReactNode[];
  iconName: IconName;
  isOpen: boolean;
  portalContainer?: Element;
  title: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  subtitle?: string;
  iconVariant: "alert" | "info" | "primary" | "success" | "warning";
};

/**
 * Wrapper around Grapes Modal to support scroll lock
 */
export function Modal({
  isOpen,
  actions,
  iconName,
  title,
  iconVariant,
  children,
  onClose,
  subtitle,
}: React.PropsWithChildren<Props>) {
  React.useLayoutEffect(() => {
    const layout = document.getElementById("root");
    if (layout) {
      layout.style.overflow = isOpen ? "hidden" : "auto";
    }
  }, [isOpen]);

  return (
    <GrapesModal
      isOpen={isOpen}
      actions={actions}
      iconName={iconName}
      iconVariant={iconVariant}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
    >
      {children}
    </GrapesModal>
  );
}
