import { authApi } from "./api";

export async function logout(): Promise<void> {
  const response = await authApi.post<string>("/logout");
  if (response?.data) {
    window.location.href = response.data;
    // To stop any future redirection that would override this one, we throw
    throw new Error("SAML logout redirection occured.");
  }

  window.location.reload();
}
