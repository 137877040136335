import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { AutocompleteMultiple, Callout } from "@dev-spendesk/grapes";

import type { SelectMultipleType } from "../../utils/schemaBuilder";
import type { FormValues } from "../../pages/ExtraFormPage/ExtraFormPage";

type Option = {
  key: string;
  label: string;
};

type Props = {
  fieldId: string;
  field: SelectMultipleType;
};
export function SelectMultipleField({ field, fieldId }: Props) {
  const { placeholder, choices } = field.properties;
  const { t } = useTranslation();
  const { touched, errors, values, setFieldError, setFieldValue } =
    useFormikContext<FormValues>();
  const options = choices.map((choice) => {
    return {
      key: choice.value,
      label: choice.label,
    };
  });
  const [filteredOptions, setOptions] = React.useState(options);
  const hasError = !!errors[fieldId] && touched[fieldId];

  const savedOptionsId = (values[fieldId] as string[]) ?? [];
  const savedOptions = savedOptionsId.map((savedOptionId) => ({
    key: savedOptionId,
    label: "", // Label is unecessary but makes typescript happy
  }));

  function filterOptions(search: string): void {
    const reg = new RegExp(search, "i");
    const newOptions = options.filter(({ label }) => reg.test(label));
    setOptions(newOptions);
  }

  function handleSelectOptions(optionsSelected: Option[]) {
    setFieldError(fieldId, undefined); // reset error when editing
    setFieldValue(
      fieldId,
      optionsSelected.map((option) => option.key),
      false
    );
  }

  return (
    <div>
      <AutocompleteMultiple
        fit="parent"
        onSearch={filterOptions}
        onSelect={handleSelectOptions}
        options={filteredOptions}
        isInvalid={hasError}
        placeholder={placeholder}
        translations={{
          selectAll: t("informations.selectedAll"),
          selected: t("informations.selected", {
            count: savedOptions.length,
          }),
        }}
        values={savedOptions}
      />
      {hasError && <Callout title={t("misc.fieldRequired")} variant="alert" />}
    </div>
  );
}
