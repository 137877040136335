import React from "react";
import { Formik, FormikContextType } from "formik";
import { useTranslation } from "react-i18next";

import { isRequiredValid, getValidationErrors } from "../../utils/validators";
import { useTree, filterVisibleValues } from "../../contexts/TreeContext";

type Props<T> = {
  initialValues: T;
  onSubmit: (values: T) => void;
  children: (formikContext: FormikContextType<T>) => React.ReactNode;
};

function Form<K extends Record<string, unknown>>({
  children,
  initialValues,
  onSubmit,
}: Props<K>) {
  const { t } = useTranslation();
  const tree = useTree();
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const visibleFields = filterVisibleValues(tree, values);
        const errors = getValidationErrors(
          visibleFields,
          isRequiredValid,
          t("misc.fieldRequired")
        );

        return errors;
      }}
      validateOnMount
      onSubmit={(values) => {
        const visibleFields = filterVisibleValues(tree, values);
        onSubmit(visibleFields);
      }}
    >
      {(formikContext) => children(formikContext)}
    </Formik>
  );
}

export default Form;
