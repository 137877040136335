import {
  PROGRESS_STATUS,
  STATUS_ONGOING,
  STATUS_START,
} from "../contexts/StatusContext";

type Values = Record<string, { status: string }>;
export function getStatusFromValues(values: Values): PROGRESS_STATUS {
  const isOnGoing = Object.values(values).some(
    (value) => value.status === "waiting_for_review"
  );
  return isOnGoing ? STATUS_ONGOING : STATUS_START;
}

export function isEditAllowed(status = ""): boolean {
  return !["waiting_for_review", "approved"].includes(status);
}
