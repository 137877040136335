import i18next, { i18n } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import onboarding from "./locales/en.json";

import {
  reportError,
  attachTagToFutureErrors,
  Severity,
} from "./utils/errorReporter/reporter";
import { LANG_LOCAL_STORAGE_KEY } from "./utils/constants";

const languages = ["fr", "en", "de"];
export type Language = typeof languages[number];

const namespaces = ["onboarding"];
export type Namespace = typeof namespaces[number];

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    lng: process.env.NODE_ENV === "test" ? "cimode" : undefined,
    fallbackLng: "en",
    supportedLngs: languages,
    backend: {
      loadPath: "/static/lang/{{lng}}/{{lng}}.json",
    },
    // For testing purpose, to avoid having to wrap every component with <Suspense>
    // Without resources, there is an async loading attempt triggered
    ...(process.env.NODE_ENV === "test" && { resources: {} }),
    ns: namespaces,
    defaultNS: "onboarding",
    fallbackNS: "onboarding",
    detection: {
      // The app language can be forced by passing a `locale` URL query param.
      // This is used by the webview rendering from the mobile apps.
      order: ["querystring", "localStorage", "navigator"],
      lookupQuerystring: "locale",
      lookupLocalStorage: LANG_LOCAL_STORAGE_KEY,
      caches: ["localStorage"],
    },
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
      format(value): string {
        return value;
      },
    },
    react: {
      useSuspense: true,
    },
    saveMissing: true,
    missingKeyHandler: (lngs, namespace, key, fallbackValue) => {
      // only report missing key for EN language
      if (!lngs.includes("en")) {
        return;
      }

      const error = new Error(`Missing i18n translation key: ${key}`);
      const errorPayload = {
        key,
        namespace,
        languages: lngs,
        fallbackValue,
      };

      reportError(error, {
        level: Severity.Warning,
        contexts: {
          i18n: errorPayload,
        },
      });
    },
    missingInterpolationHandler: (_, value) => {
      /**
       * NOTE: `value` is a weird param which looks like an array with extra
       * properties attached to it.
       */
      const interpolatedValue = value[0];
      const error = new Error(
        `Missing i18n translation interpolated value: ${interpolatedValue}`
      );
      const errorPayload = {
        input: value.input,
        missingValue: interpolatedValue,
      };

      reportError(error, {
        level: Severity.Warning,
        contexts: {
          i18n: errorPayload,
        },
      });
      /**
       * NOTE: don't return anything other than `undefined` from this function
       * because it would be used as the fallback value for the missing
       * interpolated variable.
       */
    },
  });

i18next.on("initialized", () => {
  // In dev env, append the local translations to the fetched ones
  // as some keys may not have been pushed yet
  if (process.env.NODE_ENV === "development") {
    addLocalResourceBundle();
  }
});

i18next.on("failedLoading", (lng, ns, msg) => {
  const error = new Error(`Cannot load translation files`);
  const errorPayload = {
    namespace: ns,
    language: lng,
    msg,
  };

  reportError(error, {
    level: Severity.Warning,
    contexts: {
      i18n: errorPayload,
    },
  });

  // Add the local translations as a fallback
  addLocalResourceBundle();
});

i18next.on("languageChanged", (lng) => {
  attachTagToFutureErrors("app_locale", lng);
});

function addLocalResourceBundle(): void {
  const localResource: { [ns in Namespace]: object } = {
    onboarding,
  };

  for (const [ns, res] of Object.entries(localResource)) {
    i18next.addResourceBundle("en", ns, res, true, false);
  }
}

/**
 * This function returns the language currently in use by the i18next instance.
 * @see https://github.com/i18next/react-i18next/issues/475#issuecomment-404543041
 */
export const getActiveLanguage = (instance: i18n): Language => {
  return instance.languages[0] as Language;
};

export default i18next;
