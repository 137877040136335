import React from "react";

import AsideBar from "../AsideBar/AsideBar";
import { Supervision } from "../Supervision/Supervision";

import "./Layout.scss";

const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <Supervision />
      <div className="LayoutDefault">
        <AsideBar />
        <main className="LayoutDefault__main">{children}</main>
      </div>
    </>
  );
};

export default Layout;
