import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Dedupe as DedupeIntegration } from "@sentry/integrations";
import history from "../../history";
import { FullStoryIntegration } from "./fullStoryIntegration";

import appConfig from "../config";
import { version as appVersion, name as appName } from "../../../package.json";

// Filter out Fetch & XHR breadcrumbs for external URLs:
const isExternalUrl = (targetUrl: string | undefined): boolean =>
  targetUrl !== undefined &&
  !targetUrl.startsWith("/") &&
  !targetUrl.includes(window.location.hostname);

Sentry.init({
  enabled: appConfig.stage === "production",
  whitelistUrls: [
    /https?:\/\/.*\.spendesk\.(?:com|dev|spx)/,
    /https?:\/\/.*\.linc-preview\.sh/,
  ],
  dsn: "https://cad75a657e4144a6ae2ba85c014db600@o491652.ingest.sentry.io/5641184",
  autoSessionTracking: true,
  integrations: [
    new DedupeIntegration(),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new FullStoryIntegration(),
  ],
  release: `${appName}@v${appVersion}`,
  environment: appConfig.stage,
  tracesSampleRate: 0.02,
  ignoreErrors: [
    /^network error/i,
    /^networkerror/i,
    /^failed to fetch/i,
    /^request failed/i,
    /^timeout of .* exceeded$/i,
    /^request aborted$/i,
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === "xhr") {
      // eslint-disable-next-line no-underscore-dangle
      const targetUrl = hint?.xhr?.__sentry_xhr__?.url;
      if (isExternalUrl(targetUrl)) {
        return null;
      }
    }

    if (breadcrumb.category === "fetch") {
      const targetUrl = hint?.input?.[0];
      if (isExternalUrl(targetUrl)) {
        return null;
      }
    }

    return breadcrumb;
  },
  beforeSend: (event, hint) => {
    const error = hint?.originalException;

    // Skip all Segment related errors
    if (error instanceof Error && error.stack?.includes("analytics.min.js")) {
      return null;
    }

    return event;
  },
});
