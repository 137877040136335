import React, { ChangeEvent } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { TextInput, Callout } from "@dev-spendesk/grapes";

import type { InputType } from "../../utils/schemaBuilder";
import type { FormValues } from "../../pages/ExtraFormPage/ExtraFormPage";

import "./InputField.scss";

type Props = {
  fieldId: string;
  field: InputType;
};
export function InputField({ field, fieldId }: Props) {
  const { t } = useTranslation();
  const { touched, errors, values, setFieldValue, setFieldError } =
    useFormikContext<FormValues>();

  const { label, placeholder } = field.properties;
  const text = (values[fieldId] as string) ?? "";
  const hasError = !!errors[fieldId] && touched[fieldId];

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setFieldError(fieldId, undefined); // reset error when editing
    setFieldValue(fieldId, value, false);
  }

  return (
    <div>
      <label className="Input__label">
        {label}
        <TextInput
          fit="parent"
          onChange={handleChange}
          isInvalid={hasError}
          value={text}
          placeholder={placeholder}
          className="Input__value"
        />
      </label>
      {hasError && <Callout title={t("misc.fieldRequired")} variant="alert" />}
    </div>
  );
}
