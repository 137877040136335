import { includes, values, isNil } from "lodash";
import Cookies from "js-cookie";
import i18n from "i18next";

import appConfig from "../config";

import {
  COOKIE_COOKIES_AGREEMENT,
  COOKIES_AGREEMENT_LOCAL_STORAGE_KEY,
} from "../constants";

import {
  AnalyticEventName,
  AnalyticEventPayloadDefinition,
} from "./eventRegistry";

export { AnalyticEventName };

// TODO: use this track function instead of all existing analytics.track

type EventContext = {
  company_id?: string;
  user_id?: string;
  group_id?: string;
  group_wallet_id?: string;
  organisation_id?: string;
};

// TODO: move to shared domain definition file
type User = {
  id: string;
  intercom_hash: string;
  lang: string;
  fullname: string;
  email: string;
  is_account_owner: boolean;
  is_admin: boolean;
  is_controller: boolean;
  is_requester: boolean;
};

// TODO: move to shared domain definition file
type Company = {
  id: string;
  name: string;
  organisation_id: string;
  created_from: string;
  type: string;
};

type AnalyticsLoadSettings = {
  user: User;
  company: Company;
  settings: {
    key: string;
  };
  supervisor?: {
    name: string;
    email: string;
  };
};

type IntegrationsSettings = { [key: string]: boolean | {} };

const eventContext: EventContext = {};

const setIntercomVisibility = (isVisible: boolean): void => {
  if (window.Intercom) {
    if (isVisible) {
      window.Intercom("show");
    } else {
      window.Intercom("hide");
      window.Intercom("update", { hide_default_launcher: false });
    }
  }
};

export const agreeCookiePolicy = (): void => {
  Cookies.set(COOKIE_COOKIES_AGREEMENT, "1", { expires: 365 + 31 });
  localStorage.setItem(COOKIES_AGREEMENT_LOCAL_STORAGE_KEY, "1");
};

export const hasAgreedCookiePolicy = (): boolean =>
  Boolean(Cookies.get(COOKIE_COOKIES_AGREEMENT)) ||
  Boolean(localStorage.getItem(COOKIES_AGREEMENT_LOCAL_STORAGE_KEY));

const identify = (
  user: User,
  _company: Company,
  pluginInstalled = false
): void => {
  const hasIntegrations = process.env.NODE_ENV === "production";

  const envDefinedIntegrations: IntegrationsSettings = {
    "Google Analytics": hasIntegrations,
    FullStory: hasIntegrations,
    Intercom: hasIntegrations,
    Wootric: hasIntegrations,
  };

  // Hide Intercom button when page is loaded in an Iframe
  if (window !== window.parent) {
    envDefinedIntegrations.Intercom = { hideDefaultLauncher: true };
  }

  const identifyOptions = {
    integrations: {
      ...envDefinedIntegrations,
      HubSpot: false,
      Slack: false,
    },
  };

  window.analytics.identify(
    user.id,
    {
      lang: user.lang,
      name: user.fullname,
      email: user.email,
      plugin_installed: Boolean(pluginInstalled),
      account_owner: user.is_account_owner,
      administrator: user.is_admin,
      controller: user.is_controller,
      requester: user.is_requester,
    },
    identifyOptions
  );

  // Intercom overriding
  document.addEventListener("click", (e: MouseEvent): void => {
    if (e.target && (e.target as HTMLDivElement).id === "intercom-message") {
      setIntercomVisibility(true);
    }
  });

  window.analytics.ready(() => {
    if (typeof window.Intercom !== "undefined") {
      window.Intercom(
        "onUnreadCountChange",
        (unreadCount: number) => unreadCount && setIntercomVisibility(true)
      );
    }
  });
};

export const loadAnalytics = async ({
  user,
  company,
  settings,
  supervisor,
}: AnalyticsLoadSettings): Promise<void> => {
  if (appConfig.stage !== "production") {
    return;
  }
  if (!settings || !isNil(supervisor)) {
    return;
  }

  const entityId =
    company.type === "branch_currency" ? company.created_from : company.id;
  const walletId = company.id;

  eventContext.user_id = user.id;
  eventContext.company_id = company.id;
  eventContext.group_id = entityId;
  eventContext.group_wallet_id = walletId;
  eventContext.organisation_id = company.organisation_id;

  // Intercom settings for security verification
  window.intercomSettings = {
    ...window.intercomSettings,
    user_id: user.id,
    user_hash: user.intercom_hash,
    hide_default_launcher: true,
  };

  window.wootricSettings = {
    modal_footprint: "compact",
    language: i18n.language,
  };

  // eslint-disable-next-line no-underscore-dangle
  window._fs_csp = true;

  if (window.analytics.load) {
    window.analytics.load(settings.key);

    identify(user, company);
  }
};

export const track = <EventName extends AnalyticEventName>(
  name: EventName,
  payload?: AnalyticEventPayloadDefinition[EventName]
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!includes(values(AnalyticEventName), name)) {
    console.error(`Unknown tracking event ${name}`); // eslint-disable-line no-console
  }

  // TODO: check if the payload has the right shape
  window.analytics.track(
    name,
    {
      ...eventContext,
      ...payload,
    },
    {
      integrations: {
        HubSpot: false,
        Slack: false,
      },
    }
  );
};
