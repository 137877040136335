import { size, trim, reduce } from "lodash";

/**
 * Perform validation of each element in `values` and returns a new object containing only the keys for which the
 * `validation` did not pass, with `message` as value.
 *
 * @param {Object} values An object where keys are form field names and values are their associated form field values.
 * @param {Function} validation A validation function to execute on each element of `values`.
 * @param {String} message The error message to set as value of each element of the returned object.
 * @returns {Object} An object containing only keys for which validation did not pass and with `message` as values.
 */
export const getValidationErrors = (
  values: object,
  validation: (value: string) => boolean,
  message: string
) =>
  reduce(
    values,
    (errors: Record<string, string>, value: string, key: string) => {
      if (!validation(value)) {
        return { ...errors, [key]: message };
      }

      return errors;
    },
    {}
  );

/**
 * Checks if value is not empty.
 *
 * @param {String} [value] A string to check for emptyness.
 * @returns {Boolean}
 */
export const isRequiredValid = (value: string) => size(trim(value)) > 0;
