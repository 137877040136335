import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  ROOT_NODE,
  useNode,
  useTree,
  getNode,
} from "../../contexts/TreeContext";
import {
  STATUS_DONE,
  STATUS_ONGOING,
  STATUS_START,
  useDispatchStatus,
} from "../../contexts/StatusContext";
import type { FormValues } from "../../pages/ExtraFormPage/ExtraFormPage";

import "./FormHeader.scss";

export const FormHeader = () => {
  const { t } = useTranslation();
  const tree = useTree();
  const rootNode = useNode(ROOT_NODE);
  const { values } = useFormikContext<FormValues>();
  const dispatchStatus = useDispatchStatus();

  const leafsIds = rootNode.leafsIds(values);
  const submittedInformation = leafsIds.reduce((count, id) => {
    const node = getNode(tree, id);
    if (node.status === "waiting_for_review" || node.status === "approved") {
      return count + 1;
    }
    return count;
  }, 0);

  React.useEffect(() => {
    if (submittedInformation === leafsIds.length) {
      dispatchStatus(STATUS_DONE);
    } else if (submittedInformation === 0) {
      dispatchStatus(STATUS_START);
    } else {
      dispatchStatus(STATUS_ONGOING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="FormHeader__title">
      {t("countMissingInformation", {
        count: leafsIds.length - submittedInformation,
      })}
    </div>
  );
};
