import * as Sentry from "@sentry/react";
import type { CaptureContext } from "@sentry/types";

export { Severity } from "@sentry/react";

export const attachTagToFutureErrors = Sentry.setTag;

export function reportError<T extends Error>(
  error: T,
  options?: CaptureContext
): void {
  Sentry.captureException(error, options);
}
