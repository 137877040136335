import React from "react";

export const STATUS_START = "start";
export const STATUS_ONGOING = "ongoing";
export const STATUS_DONE = "done";

export type PROGRESS_STATUS =
  | typeof STATUS_START
  | typeof STATUS_ONGOING
  | typeof STATUS_DONE;

const StatusContext = React.createContext<PROGRESS_STATUS>(STATUS_START);
export const useStatus = () => React.useContext(StatusContext);

type DispatchStatus = (status: PROGRESS_STATUS) => void;
const DispatchStatusContext =
  React.createContext<undefined | DispatchStatus>(undefined);

export const useDispatchStatus = () => {
  const context = React.useContext(DispatchStatusContext);
  if (context === undefined) {
    throw new Error("useDispatchStatus must be used within a StatusProvider");
  }
  return context;
};

export const StatusProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [status, setStatus] = React.useState<PROGRESS_STATUS>(STATUS_START);
  return (
    <StatusContext.Provider value={status}>
      <DispatchStatusContext.Provider value={setStatus}>
        {children}
      </DispatchStatusContext.Provider>
    </StatusContext.Provider>
  );
};
