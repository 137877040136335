import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router, Route, Switch } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import history from "./history";
import i18n from "./i18n";
import { UserProvider } from "./contexts/UserContext";
import { StatusProvider } from "./contexts/StatusContext";

import { Loader } from "./components/Loader/Loader";

import ExtraFormPage from "./pages/ExtraFormPage/ExtraFormPage";
import { NoMatch } from "./pages/NoMatch/NoMatch";

const queryClient = new QueryClient();
function App() {
  return (
    <Suspense fallback={<Loader />}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <StatusProvider>
            <Router history={history}>
              <Switch>
                <Route path="/:companyId/extra/:procedureId/:formId" exact>
                  <UserProvider>
                    <ExtraFormPage />
                  </UserProvider>
                </Route>
                <Route>
                  <NoMatch />
                </Route>
              </Switch>
            </Router>
          </StatusProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </Suspense>
  );
}

export default App;
