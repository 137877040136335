import React from "react";
import { useFormikContext } from "formik";

import { OptionGroup } from "@dev-spendesk/grapes";

import type { SelectType } from "../../utils/schemaBuilder";
import type { FormValues } from "../../pages/ExtraFormPage/ExtraFormPage";

type Props = {
  fieldId: string;
  field: SelectType;
};
export function SelectField({ field, fieldId }: Props) {
  const { choices } = field.properties;
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const options = choices.map((choice) => {
    return {
      value: choice.value,
      label: choice.label,
    };
  });

  const savedOptionId = values[fieldId] as string;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setFieldValue(fieldId, value);
  }

  return (
    <OptionGroup
      name="optionGroupField"
      onChange={handleChange}
      options={options}
      value={savedOptionId}
    />
  );
}
