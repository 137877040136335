import React, { ReactNode, ReactElement, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@dev-spendesk/grapes";
import { Modal } from "../Modal/Modal";

export type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  title: string;
};

const PreviewModal = ({
  children,
  isOpen,
  onClose,
  title,
}: Props): ReactElement | null => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      actions={[
        <Button
          key="yes"
          text={t("previewModal.return")}
          variant="secondary"
          onClick={onClose}
        />,
      ]}
      onClose={onClose}
      iconName="download"
      iconVariant="primary"
      title={title}
    >
      {children}
    </Modal>
  );
};

export default PreviewModal;
