import { map, forEach } from "lodash";
import { api } from "./api";
import config from "./config";

export const FILE_UPLOAD_LIMIT_MB = 10; // 10Mb
export const FILE_UPLOAD_LIMIT = FILE_UPLOAD_LIMIT_MB * 1024 * 1024;
export const getFilePreviewUrl = (
  fileId: string,
  accountType: string = "kyb"
): string => `${config.mediasBaseUrl}/${accountType}/preview/${fileId}`;

type FileUploadResponse = { id: string; name: string };
const addFilePreviewUrl = (
  fileData: FileUploadResponse,
  accountType: string
): FileUploaded => {
  return {
    ...fileData,
    url: getFilePreviewUrl(fileData.id, accountType),
  };
};

export function isFileTooLarge(file?: File): boolean {
  return !!file && file.size > FILE_UPLOAD_LIMIT;
}

export type FileUploaded = {
  id: string;
  url: string;
  name: string;
};
export const uploadFiles = async (
  companyId: string,
  attachedTo: string,
  files: FileList,
  accountType: string = "kyb"
): Promise<FileUploaded[]> => {
  const formData = new FormData();
  forEach(files, (file) => formData.append("files", file));

  const params = {
    attachedTo,
    accountType,
  };
  const { data } = await api.post<FileUploadResponse[]>(
    `/${companyId}/kyb-files`,
    formData,
    params
  );
  return map(data, (file) => addFilePreviewUrl(file, accountType));
};

export const deleteFile = async (
  companyId: string,
  fileId: string,
  accountType: string
): Promise<void> => {
  const params = { accountType };
  await api.delete(`/${companyId}/kyb-files/${fileId}`, params);
};
