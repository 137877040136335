import React from "react";

export function Loader() {
  return (
    <div className="global-loader active">
      <img
        src={`${process.env.PUBLIC_URL}/static/img/logo-loading.gif`}
        alt="loading"
      />
    </div>
  );
}
