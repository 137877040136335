import React from "react";
import type { Schema, FieldType, Condition } from "../utils/schemaBuilder";

export type Node = {
  ancestorId: string | null;
  descendantsIds: string[];
  field: FieldType | null;
  status?: string;
};
export type Tree = Map<string, Node>;

const TreeContext = React.createContext<Tree | null>(null);
export const ROOT_NODE = "root_node";

const OperatorFn = {
  eq: (a: unknown, b: unknown) => a === b,
};

function resolveConditions(
  conditions: Condition[] | undefined,
  values: Record<string, unknown>
) {
  if (conditions === undefined || conditions.length === 0) {
    return true;
  }

  const a = conditions.every((condition) => {
    const { field, value, operator } = condition;
    const formValue = values[field] as unknown;
    return formValue ? OperatorFn[operator](formValue, value) : false;
  });
  return a;
}

/**
 * A node has an error only and only if:
 * - At least one descendant has an error
 * - All ancestors are visible
 */
function isNodeInError(
  tree: Tree,
  nodeId: string,
  errors: Record<string, string | undefined>,
  values: Record<string, unknown>
): boolean {
  if (isNodeVisible(tree, nodeId, values)) {
    const node = getNode(tree, nodeId);
    return node.descendantsIds.reduce((acc: boolean, descendantId) => {
      return acc || isNodeInError(tree, descendantId, errors, values);
    }, errors[nodeId] !== undefined);
  }
  return false;
}

export function filterVisibleValues<K extends Record<string, unknown>>(
  tree: Tree,
  values: K
): K {
  return Object.entries(values).reduce((acc, [fieldId, value]) => {
    if (isNodeVisible(tree, fieldId, values)) {
      return Object.assign(acc, { [fieldId]: value });
    }
    return acc;
  }, {} as K);
}

/**
 * A node is visible only and only if all its ancestors are visible
 */
export function isNodeVisible(
  tree: Tree,
  nodeId: string,
  values: Record<string, unknown>,
  distance = 1
): boolean {
  const node = getNode(tree, nodeId);

  const conditions = node.field?.properties.conditions;
  const isVisible = resolveConditions(conditions, values);
  if (node.ancestorId !== null && distance > 0) {
    return (
      isVisible && isNodeVisible(tree, node.ancestorId, values, distance - 1)
    );
  }

  return isVisible;
}

export const getNode = (tree: Tree, nodeId: string) => {
  const node = tree.get(nodeId);
  if (!node) {
    throw new Error(`Node ${nodeId} doesn't exist`);
  }
  return node;
};

function getVisibleLeafsIds(
  tree: Tree,
  nodeId: string,
  values: Record<string, unknown>
): string[] {
  if (isNodeVisible(tree, nodeId, values, 0)) {
    const node = getNode(tree, nodeId);
    if (node.descendantsIds.length > 0) {
      return node.descendantsIds.reduce((acc: string[], descendantId) => {
        return acc.concat(getVisibleLeafsIds(tree, descendantId, values));
      }, []);
    }
    return [nodeId];
  }
  return [];
}

export const useTree = () => {
  const tree = React.useContext(TreeContext);
  if (tree === null) {
    throw new Error("useTree should be within a TreeProvider");
  }
  return tree;
};

export const useNode = (nodeId: string) => {
  const tree = useTree();

  const hasError = (
    errors: Record<string, string | undefined>,
    values: Record<string, unknown>
  ): boolean => {
    return isNodeInError(tree, nodeId, errors, values);
  };

  const isVisible = (values: Record<string, string>): boolean => {
    return isNodeVisible(tree, nodeId, values);
  };

  const leafsIds = (values: Record<string, unknown>): string[] => {
    return getVisibleLeafsIds(tree, nodeId, values);
  };

  /**
   * Determines the status of a field. Fieldset (like Origin of funds) don't have
   * a status. In those cases, we use the status of one of the child fields. They should all
   * have the same status so it doesn't matter as long as it is defined
   */
  const getStatus = (values: Record<string, unknown>): string | undefined => {
    const leafs = getVisibleLeafsIds(tree, nodeId, values);
    const ids = leafs.concat(nodeId);
    return ids.reduce((finalStatus: string | undefined, id) => {
      const node = getNode(tree, id);
      return node.status ?? finalStatus;
    }, undefined);
  };

  return {
    node: getNode(tree, nodeId),
    hasError,
    isVisible,
    leafsIds,
    getStatus,
  };
};

export const TreeProvider = ({
  children,
  schema,
  submittedValues,
}: React.PropsWithChildren<{
  schema: Schema;
  submittedValues: Record<
    string,
    { id: string; value: string; status: string }
  >;
}>) => {
  const { layout, fields } = schema;
  const tree = new Map<string, Node>();

  function addNode(ancestorId: string, nodeId: string): void {
    const field = fields[nodeId];

    const node: Node = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore it doesn't matter if fieldsIds is not defined, fallback on []
      descendantsIds: field.properties.fieldsIds ?? [],
      ancestorId,
      field,
      status: submittedValues[nodeId]?.status,
    };

    tree.set(nodeId, node);
    return node.descendantsIds.forEach((descendantId) =>
      addNode(nodeId, descendantId)
    );
  }

  layout.forEach(({ fieldsIds }) => {
    tree.set(ROOT_NODE, {
      descendantsIds: fieldsIds,
      ancestorId: null,
      field: null,
    });
    fieldsIds.forEach((fieldId) => {
      addNode(ROOT_NODE, fieldId);
    });
  });

  return <TreeContext.Provider value={tree}>{children}</TreeContext.Provider>;
};
