import React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Button } from "@dev-spendesk/grapes";

import Form from "../../components/Form/Form";
import BottomBar from "../../components/BottomBar/BottomBar";
import Layout from "../../components/Layout/Layout";
import { FormHeader } from "../../components/FormHeader/FormHeader";
import { Loader } from "../../components/Loader/Loader";
import { Modal } from "../../components/Modal/Modal";

import { useUser } from "../../contexts/UserContext";
import { TreeProvider } from "../../contexts/TreeContext";

import { api, redirectToSpendeskDashboard } from "../../utils/api";
import { SchemaBuilder, Schema } from "../../utils/schemaBuilder";
import { getActiveLanguage } from "../../i18n";

import "./ExtraFormPage.scss";

export type FormValues = Record<string, string | string[] | null>;
type FormAPIResponse = {
  status: string;
  schema: Schema;
  values: Record<string, { id: string; value: string; status: string }>;
};

function removeNullValues(values: FormValues): Record<string, string> {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (value === null) {
      return acc;
    }
    return Object.assign(acc, { [key]: value });
  }, {});
}

function ExtraPage() {
  const { t, i18n } = useTranslation();
  const user = useUser();
  const [submitFailed, setSubmitFailed] = React.useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const { companyId, procedureId, formId } = useParams<{
    companyId: string;
    formId: string;
    procedureId: string;
  }>();
  const locale = getActiveLanguage(i18n);

  const { data, isLoading, isError } = useQuery(
    ["schema", locale],
    () =>
      api.get<FormAPIResponse>(
        `/${companyId}/procedures/${procedureId}/forms/${formId}?locale=${locale}`
      ),
    {
      staleTime: Infinity,
    }
  );

  const { mutateAsync: saveValues } = useMutation((payload: FormValues) =>
    api.post(
      `/${companyId}/procedures/${procedureId}/forms/${formId}/submissions`,
      payload
    )
  );
  const { mutateAsync: submitValues } = useMutation(() =>
    api.post(`/${companyId}/procedures/${procedureId}/forms/${formId}/submit`)
  );

  async function saveAndLeave(values: FormValues, submit: boolean) {
    const definedValues = removeNullValues(values);
    setOpenConfirmModal(false);
    try {
      await saveValues(definedValues);
      if (submit) {
        await submitValues();
      }
      redirectToSpendeskDashboard();
    } catch (err) {
      setSubmitFailed(true);
    }
  }

  if (isError) {
    redirectToSpendeskDashboard();
    return null;
  }

  if (isLoading || data?.data === undefined) {
    return <Loader />;
  }

  if (data.data.status !== "ongoing") {
    redirectToSpendeskDashboard();
    return null;
  }

  const { schema, values: submittedValues } = data.data;

  const initialValues: FormValues = Object.entries(schema.fields)
    .filter(([, field]) => field.type !== "fieldset")
    .reduce((acc: Record<string, string>, [fieldId]) => {
      acc[fieldId] = submittedValues[fieldId]?.value ?? null;
      return acc;
    }, {});

  return (
    <TreeProvider schema={schema} submittedValues={submittedValues}>
      <Layout>
        <div className="ExtraPage__container">
          <h1 className="ExtraPage__header">{`${t("hiFirstname", {
            firstName: user?.fullname,
          })}`}</h1>
          <Form
            initialValues={initialValues}
            onSubmit={(values) => saveAndLeave(values, true)}
          >
            {({ handleSubmit, submitForm, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormHeader />
                  <SchemaBuilder values={values} />

                  <BottomBar
                    error={submitFailed}
                    primaryAction={() => setOpenConfirmModal(true)}
                    secondaryAction={() => saveAndLeave(values, false)}
                  />

                  <Modal
                    isOpen={openConfirmModal}
                    actions={[
                      <Button
                        key="no"
                        text={t("confirmModal.no")}
                        variant="secondary"
                        onClick={() => setOpenConfirmModal(false)}
                      />,
                      <Button
                        key="yes"
                        text={t("confirmModal.yes")}
                        variant="primary"
                        type="submit"
                        onClick={submitForm}
                      />,
                    ]}
                    iconName="lock"
                    iconVariant="primary"
                    title={t("confirmModal.title")}
                  >
                    {t("confirmModal.content")}
                  </Modal>
                </form>
              );
            }}
          </Form>
        </div>
      </Layout>
    </TreeProvider>
  );
}

export default ExtraPage;
