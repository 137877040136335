import { apiUrl, wwwBaseUrl, authBaseUrl } from "./config";

type Method = "GET" | "POST" | "DELETE";

type Config = {
  method: Method;
  url: string;
  params?: Record<string, string>;
  headers?: Record<string, string>;
  body?: string | FormData;
};
interface Res<T> {
  config: Config;
  data: T;
}

export function redirectToSpendeskDashboard(): void {
  const DASHBOARD_URL = `${wwwBaseUrl}/app`;
  window.location.href = `${DASHBOARD_URL}?referer=kyb`;
}

class Api {
  protected baseUrl: string;
  protected loginPageUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.loginPageUrl = `${wwwBaseUrl}/auth/login`;
  }

  redirectToLoginPage(): void {
    const href = `${this.loginPageUrl}?${new URLSearchParams({
      "session-expired": "1",
      targetUrl: window.location.href,
    })}`;

    window.location.href = href;
  }

  async run<T = {}>(config: Config): Promise<Res<T>> {
    const { method, url, params, headers, body } = config;
    const queryParams = params ? `?${new URLSearchParams(params)}` : "";
    const queryHeaders =
      body instanceof FormData
        ? headers
        : {
            ...headers,
            "Content-Type": "application/json",
          };

    const response = await fetch(this.baseUrl + url + queryParams, {
      method,
      credentials: "include",
      headers: queryHeaders,
      body,
    });

    if (response.status === 401) {
      this.redirectToLoginPage();
    }

    const res: Res<T> = {
      config,
      data: /application\/json/.test(response.headers.get("Content-Type") ?? "")
        ? await response.json()
        : (await response.text()) || null,
    };

    for (const i in response) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof response[i] !== "function") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        res[i] = response[i];
      }
    }

    if (response.ok) {
      return res;
    }
    throw res;
  }

  async get<T = {}>(
    url: string,
    params?: Record<string, string>
  ): Promise<Res<T>> {
    return this.run<T>({ method: "GET", url, params });
  }

  async post<T = {}>(
    url: string,
    body?: FormData | Record<string, unknown>,
    params?: Record<string, string>
  ): Promise<Res<T>> {
    const queryBody = body instanceof FormData ? body : JSON.stringify(body);

    return this.run<T>({
      method: "POST",
      url,
      params,
      body: queryBody,
    });
  }

  async delete<T>(
    url: string,
    params: Record<string, string>
  ): Promise<Res<T>> {
    return this.run({ method: "DELETE", url, params });
  }
}

export const api = new Api(apiUrl);
export const authApi = new Api(authBaseUrl);
