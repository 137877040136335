export const {
  apiConfig,
  stage,
  wwwBaseUrl,
  apiUrl,
  mediasBaseUrl,
  authBaseUrl,
} = window.appConfig;

export default window.appConfig;
